<template>
  <div class="v-stack gap-3 h-stretch">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="header">
          <div>Add Pricelist</div>
        </div>
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-3">
            <input v-model="newPricelistName" type="text" placeholder="name" />
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="addBasePricelist()" class="edit">Add</button>
        </div>
      </div>
    </div>

    <div class="h-stack h-space-between" v-if="!editable">
      <div class="h-stack gap-3">
        <select
          v-model="selectedPricelist"
          @change="onPricelistSelected()"
          style="width: 150px"
        >
          <option
            v-for="pricelist in basePricelists"
            :key="pricelist._id"
            :value="pricelist"
          >
            {{ pricelist.name }}
          </option>
        </select>
        <button class="add" @click="addDialog()">+</button>
      </div>
      <div class="h-stack gap-3">
        <button
          class="submit"
          @click="exportPdf()"
          v-if="$store.state.accessLevel > 5"
        >
          Export
        </button>
        <button class="submit">Order</button>
        <template v-if="$store.state.accessLevel > 8">
          <button class="submit" @click="$router.push('/pricelist/templates')">
            Templates
          </button>
          <button
            v-if="selectedPricelist"
            class="add"
            @click="
              $router.push('/pricelist/item/import/' + selectedPricelist._id)
            "
          >
            Import
          </button>
          <button
            v-if="selectedPricelist"
            class="add"
            @click="
              $router.push('/pricelist/item/add/' + selectedPricelist._id)
            "
          >
            Add
          </button>
        </template>
      </div>
    </div>
    <div
      class="h-stack gap-3 h-end"
      v-if="$store.state.accessLevel > 5 && selectedPricelist"
    >
      <button class="edit" @click="editPrice()" v-if="!editable">Edit</button>
      <button class="submit" @click="commitPrice()" v-if="editable">
        Save
      </button>
    </div>
    <input v-if="editable" type="text" v-model="selectedPricelist.name" />
    <TablePricelistItems
      :listItems="items"
      :pushOnClick="true"
      :priceEditable="editable"
      v-on:onDragAndDrop="onDragAndDrop"
      v-on:deleteItem="deleteItem"
    ></TablePricelistItems>
    <div
      class="h-stack gap-3 h-end"
      v-if="$store.state.accessLevel > 5 && selectedPricelist"
    >
      <button class="submit" @click="commitPrice()" v-if="editable">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TablePricelistItems from "@/components/TablePricelistItems.vue";
import { mapActions } from "vuex";
import ExportPdf from "@/exportpdf.js";

export default {
  components: {
    TablePricelistItems,
  },
  data() {
    return {
      newPricelistName: "",
      selectedPricelist: null,
      basePricelists: [],
      editable: false,
    };
  },
  computed: {
    items() {
      if (this.selectedPricelist) {
        let items = this.selectedPricelist.items;

        if (this.$store.state.accessLevel <= 5) {
          items = items.filter(
            (item) => item.item.categoryMain != "PERSONÁL / SLUŽBY"
          );
        }

        return items;
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      "getPricelistItems",
      "addPricelist",
      "getPricelists",
      "updatePricelist",
      "reorderPricelistItem",
      "removePricelistItem",
    ]),
    onPricelistSelected() {
      this.$router.push("/pricelist?pricelist=" + this.selectedPricelist._id);
    },
    deleteItem(item) {
      this.selectedPricelist.items = this.selectedPricelist.items.filter(
        (selectedItem) => item.item._id != selectedItem.item._id
      );

      this.removePricelistItem({
        _id: item.item._id,
        pricelist: this.selectedPricelist._id,
      })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editPrice() {
      this.editable = true;
    },
    commitPrice() {
      this.updatePricelist(this.selectedPricelist)
        .then(() => {
          this.editable = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportPdf() {
      if (this.selectedPricelist) {
        const exporter = new ExportPdf();
        const items = this.selectedPricelist.items.map((item) => {
          const newItem = item.item;
          newItem.price = item.price;
          return newItem;
        });
        exporter.export(items, null);
      }
    },
    addDialog() {
      this.newPricelistName = "";
      this.$refs.overlay.classList.add("show");
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    addBasePricelist() {
      if (this.newPricelistName.length > 0) {
        this.addPricelist({
          name: this.newPricelistName,
          base: true,
          template: false,
        })
          .then(() => {
            this.refresh();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.dismiss();
    },
    onDragAndDrop(data) {
      const sourceItem = data.sourceItem;
      const targetItem = data.targetItem;
      this.reorderPricelistItem({
        sourceItem: sourceItem.item._id,
        targetItem: targetItem.item._id,
        pricelist: this.selectedPricelist._id,
      })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refresh() {
      // this.getPricelistItems()
      //   .then((items) => {
      //     items;

      this.getPricelists()
        .then((pricelists) => {
          this.basePricelists = pricelists
            .filter((item) => item.base)
            .sort((a, b) => {
              return a.name.localeCompare(b.name, "cs");
            });

          if (this.basePricelists.length > 0) {
            if (this.$route.query.pricelist) {
              for (const pricelist of this.basePricelists) {
                if (pricelist._id == this.$route.query.pricelist) {
                  this.selectedPricelist = pricelist;
                }
              }
            } else {
              this.selectedPricelist = this.basePricelists[0];
              this.onPricelistSelected();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
